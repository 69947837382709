import React, { Component } from 'react';
import { BrowserRouter } from "react-router-dom";
import LandingPage from './views/landing-page';
import ScrollToTop from './Components/scroll-to-top';
import './App.css';

var defaultSettings = {
  matchPage: {
    isOpenStats: true,
    isOpenH2h: true,
    isOpenTeams: true,
    isOpenForm: true,
  }
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    // Retrieve the object from storage
    this.retrievedObject = JSON.parse(localStorage.getItem('settings'));
    // if it is the first time user visit the page then save default setttings
    this.settings = (this.retrievedObject === null ? defaultSettings : this.retrievedObject);
    // Put the object into storage
    localStorage.setItem('settings', JSON.stringify(this.settings));

  }

  render() {
    return (
      <BrowserRouter>
        <ScrollToTop />
        <div className="App">
          <LandingPage />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;