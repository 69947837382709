import React, { Component } from 'react';
import Header from '../Components/header';
import MainContainer from '../Components/main-container';
import Footer from '../Components/footer';
import Advert120 from '../Components/advert/ad120x600';

class LandingPage extends Component {
  render() {
    return (
      <div>
        <Header />
        <div className="takeover">
          <div className="takeover_left">
            <Advert120 />
          </div>
          <div className="takeover_right">
            <Advert120 />
          </div>
        </div>
        <MainContainer />
        <Footer />
      </div>
    );
  }
}

export default LandingPage;