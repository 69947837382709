/* eslint-disable jsx-a11y/alt-text */
import React, { Component, Suspense, lazy } from 'react';
import { Route, Switch } from "react-router-dom";
import '../css/main-container.css';
// import MainMatchPage from './match/main-match-page';
// import MainWeb from './main-web';
import Advert320 from './advert/ad320x50';
import Advert720 from './advert/ad728x90';

const MainMatchPage = lazy(() => import('./match/main-match-page'));
const MainWeb = lazy(() => import('./main-web'));

class MainContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }


  render() {
    return (
      <div className="main side-space">
        <div className="top-banner">
          <Advert720 />
        </div>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route exact path="/match/:id" component={MainMatchPage} />
            <Route path="/" component={MainWeb} />
          </Switch>
        </Suspense>
        <Advert320 />
        <div className="footer-p">
          <p>Placard da bola oferece resultados de futebol em direto e jogos ao vivo de mais 500 ligas diferentes. Livescore, resultados ao vivo, canais de televisão, onde ver os jogos.</p>
          <p>Acompanha jogos em direto com relato ao vivo dos principais acontecimentos de futebol, das principais ligas: Liga dos campeões, Liga Europa, Premier League, Serie A, Primeira liga, Segunda liga, Taça de Portugal, Taça da liga, Brasileirão, Copa libertadores, Campeonato do mundo de clubes, Campeonato do mundo, Europeu, e muito mais.</p>
          <p>Se gostas de Futebol e queres saber mais sobre Apostas Desportivas, recomendámos que visites o site <a href="https://www.academiadasapostas.com/">Academia das Apostas</a>. Lá encontrarás alguns dos melhores <a href="https://www.academiadasapostas.com/blog/bet">artigos de apostas</a> que tivemos oportunidade de ler, e um fantástico <a href="https://www.academiadasapostas.com/courses">Curso em vídeo do Paulo Rebelo</a>, um dos melhores e mais bem sucedidos apostadores do mundo.</p>
        </div>
      </div>
    );
  }
}

export default MainContainer;