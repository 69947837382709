import React from 'react';

export default class Ad728x90 extends React.Component {

  render() {
    return (
      <div className='ad728x90'>
        <iframe title="ad728x90"
          scrolling='no'
          frameBorder='0'
          width='728'
          height='90'
          src="https://wlbetclicpt.adsrv.eacdn.com/I.ashx?btag=a_72b_89c_&amp;affid=72&amp;siteid=72&amp;adid=89&amp;c=" >
        </iframe>
      </div>
    );
  }
}