import React from 'react';

export default class Ad120x600 extends React.Component {

  render() {
    return (
      <div className='ad120x600'>
        <iframe title="ad120x600"
          scrolling='no' frameBorder='0'
          width='121'
          height='601'
          src="https://wlbetclicpt.adsrv.eacdn.com/I.ashx?btag=a_72b_95c_&affid=72&siteid=72&adid=95&c=" >
        </iframe>
      </div>
    );
  }
}