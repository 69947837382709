import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
        <footer className="Footer">
          © 2019 Placardabola. Todos os direitos reservados.
      </footer>
    );
  }
}

export default Footer;