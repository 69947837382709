import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom'

class Header extends Component {
  render() {
    let isHomepage = false;
    try {
      isHomepage = this.props.location.pathname === '/';
    } catch (error) {
      // do nothing
    }

    return (

      <header className="header side-space">
        <div className="header-container">
          <Link to="/">
            <img className="logo" src={"/img/logo.svg"} title="Placar da Bola" alt="Placardabola Logo" />
          </Link>
          {isHomepage ? (
            <h1>Placard'a bola é uma forma mais fácil de acompanhar os resultados ao vivo de futebol.</h1>
          ) : (
            <span className="like-h1">Placard'a bola é uma forma mais fácil de acompanhar os resultados ao vivo de futebol.</span>
          )}
          <p>Futebol em direto, jogos ao vivo e livescore.</p>
        </div>
      </header >
    );
  }
}

export default withRouter(Header);