import React from 'react';

export default class Ad320x50 extends React.Component {

  render() {
    return (
      <div className='ad320x50'>
        <iframe title="ad320x50"
          scrolling='no'
          frameBorder='0'
          width='320'
          height='50'
          src="https://wlbetclicpt.adsrv.eacdn.com/I.ashx?btag=a_72b_147c_&amp;affid=72&amp;siteid=72&amp;adid=147&amp;c=">
        </iframe>
      </div>
    );
  }
}